import { css } from "styled-components";

export const latoStack =
  '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';

export const ptSansStack =
  '"PT Sans", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';

export const siteTitle = css`
  font-family: ${ptSansStack};
  font-size: 2.3em;
  font-weight: 900;
  line-height: 1.1;

  @media (min-width: 32em) {
    font-size: 2.7em;
  }

  @media (min-width: 42em) {
    font-size: 3.4em;
  }
`;

export const pageTitle = css`
  font-family: ${ptSansStack};
  font-size: 2.3em;
  font-weight: 900;
  line-height: 1.1;

  @media (min-width: 32em) {
    font-size: 2.6em;
  }

  @media (min-width: 42em) {
    font-size: 3em;
  }
`;

export const sectionHeading = css`
  position: relative;
  margin-top: 1.5em;
  font-family: ${ptSansStack};
  font-weight: normal;
  font-size: 2em;
  line-height: 1.2;
`;

export const subHeading = css`
  font-family: ${ptSansStack};
  font-weight: bold;
  font-size: 1.8em;
  line-height: 1.2;
`;

export const bodyCopy = css`
  font-family: ${latoStack};
  font-weight: 300;
  line-height: 1.5;
`;
