import styled from "styled-components";

export const Container = styled.div`
  max-width: 60em;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;

  @media (min-width: 32em) {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  @media (min-width: 50em) {
    padding-left: 3rem;
  }
`;
