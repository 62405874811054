import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import styled, {
  createGlobalStyle,
  ThemeProvider,
  css,
} from "styled-components";
import "typeface-pt-sans";
import KCS from "../tokens/colours";
import Header from "./header";
import Footer from "./footer";
import { bodyCopy } from "../tokens/typography";
import { Container } from "./container";
import SkipNavigation from "./skipNavigation";

const Layout = ({ children, hero = null, isHome = false }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  const skipNavId = "mainContent";

  return (
    <ThemeProvider theme={KCS}>
      <GlobalStyle />
      <LayoutWrapper>
        <SkipNavigation skipNavId={skipNavId} />
        <Sidebar>
          <SidebarContainer>
            <Header siteTitle={data.site.siteMetadata.title} />
          </SidebarContainer>
        </Sidebar>
        <Main isHome={isHome}>
          {hero}
          <Container>{children}</Container>
        </Main>
        <FooterWrapper>
          <FooterContainer>
            <Footer siteTitle={data.site.siteMetadata.title} />
          </FooterContainer>
        </FooterWrapper>
      </LayoutWrapper>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
    height: 100%;
    font-size: 18px;
    line-height: 1.5;

    @media (min-width: 32em) {
      font-size: 24px;
    }
  }

  body {
    ${bodyCopy};
    height: 100%;
    margin: 0;
    color: ${({ theme }) => theme.palette.black}
  }

  #___gatsby,
  #gatsby-focus-wrapper {
    height: 100%;
  }

  svg {
    display: block;
    fill: currentColor;
  }

  a {
    color: inherit;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  ol,
  dl {
    margin: 1em 0 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.2;
  }
`;

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;

  @media (min-width: 50em) {
    display: grid;
    grid-template-columns: 30% 70%;
    grid-template-rows: 1fr auto;
    height: 100vh;
    background-image: linear-gradient(
      ${({ theme }) => theme.palette.darkerLightBlue},
      ${({ theme }) => theme.palette.darkBlue}
    );
    background-color: ${({ theme }) => theme.palette.darkerLightBlue};
  }
`;

const bottomPadding = ({ isHome }) =>
  !isHome &&
  css`
    &::after {
      content: "";
      display: block;
      padding-bottom: 4rem;
    }
  `;

const Main = styled.main`
  ${bottomPadding};

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  z-index: 1;
  background-color: ${({ theme }) => theme.palette.white};

  @media (min-width: 50em) {
    display: block;
    grid-row: span 2;
    grid-column: 2;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
`;

const Sidebar = styled.div`
  background-image: linear-gradient(
    ${({ theme }) => theme.palette.darkerLightBlue},
    ${({ theme }) => theme.palette.darkBlue}
  );
  background-color: ${({ theme }) => theme.palette.darkerLightBlue};
  color: ${({ theme }) => theme.palette.white};

  @media (min-width: 50em) {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background-image: none;
    background-color: transparent;
  }
`;

const SidebarContainer = styled.div`
  @media (min-width: 50em) {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: 2rem;
    padding-left: 2rem;
    padding-bottom: 2rem;
  }

  @media (min-width: 70em) {
    max-width: 14rem;
  }
`;

const FooterWrapper = styled.div`
  background-image: linear-gradient(
    ${({ theme }) => theme.palette.darkerLightBlue},
    ${({ theme }) => theme.palette.darkBlue}
  );
  background-color: ${({ theme }) => theme.palette.darkerLightBlue};
  color: ${({ theme }) => theme.palette.white};

  @media (min-width: 50em) {
    position: relative;
    z-index: 2;
    background-image: none;
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const FooterContainer = styled.div`
  @media (min-width: 50em) {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: 2rem;
    padding-left: 2rem;
    position: sticky;
    top: 0;
  }

  @media (min-width: 70em) {
    max-width: 14rem;
  }
`;

export default Layout;
