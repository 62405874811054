const KCS = {
  palette: {
    lightBlue: "#5CAECD",
    darkerLightBlue: "#217eac",
    darkBlue: "#283278",
    white: "#EEE",
    black: "#333",
    green: "#138c59",
  },
  shadows: {},
};

export default KCS;
