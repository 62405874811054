import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Container as CoreContainer } from "./container";
import { fancyLinkStyles } from "../tokens/utils";
import { transparentize } from "polished";
import { focusState } from "../tokens/a11y";

const Footer = ({ siteTitle }) => {
  const { footerNavigation } = useStaticQuery(
    graphql`
      query {
        footerNavigation: contentfulNavigation(
          slug: { eq: "footer-navigation" }
        ) {
          navigationItems {
            id
            title
            slug
          }
        }
      }
    `
  );

  const { navigationItems: footerNavigationItems } = footerNavigation;
  return (
    <FooterWrapper>
      <Container>
        <p>
          &copy; {new Date().getFullYear()} {siteTitle}
        </p>
        {footerNavigationItems && (
          <LinksList>
            {footerNavigationItems.map(item => (
              <LinksListItem key={item.id}>
                <LinksListLink to={`/${item.slug}/`}>
                  {item.title}
                </LinksListLink>
              </LinksListItem>
            ))}
          </LinksList>
        )}
        <p>
          Created by{" "}
          <LinksListLink as="a" href="https://www.corseconcierge.com">
            Corse Concierge
          </LinksListLink>
        </p>
      </Container>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.footer`
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 0.8em;
`;

const Container = styled(CoreContainer)`
  width: 100%;

  @media (min-width: 50em) {
    padding: 0;
  }
`;

const LinksList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const LinksListItem = styled.li`
  margin-top: 0.5rem;
`;

const LinksListLink = styled(Link)`
  ${fancyLinkStyles(
    ({ theme }) => transparentize(0.6, theme.palette.lightBlue),
    ({ theme }) => transparentize(0.2, theme.palette.white),
    "0.2em",
    5
  )};
  display: inline-block;
  text-decoration: none;

  &:focus {
    ${focusState(({ theme }) => theme.palette.white)};
  }
`;

export default Footer;
