import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useStaticQuery, graphql, Link } from "gatsby";
import { ReactComponent as LogoSvg } from "../svg/logo.svg";
import { hideVisually, transparentize } from "polished";
import { Container as CoreContainer } from "./container";
import { focusState } from "../tokens/a11y";
import { fancyLinkStyles } from "../tokens/utils";

const Header = ({ siteTitle }) => {
  const { mainNavigation } = useStaticQuery(
    graphql`
      query {
        mainNavigation: contentfulNavigation(slug: { eq: "main-navigation" }) {
          navigationItems {
            id
            title
            slug
            hideOnMobileNavigation
            childContent {
              ... on ContentfulService {
                __typename
                id
                slug
                title
              }
            }
          }
        }
      }
    `
  );

  const { navigationItems: mainNavigationItems } = mainNavigation;
  return (
    <HeaderWrapper>
      <Container>
        <LogoLink to="/">
          <Logo />
          <VisuallyHidden>{siteTitle}</VisuallyHidden>
        </LogoLink>
        {mainNavigationItems && mainNavigationItems.length > 0 && (
          <NavList>
            {mainNavigationItems.map(item => {
              const services =
                item.childContent &&
                item.childContent.length > 0 &&
                item.childContent.filter(
                  item => item.__typename === "ContentfulService"
                );
              return (
                <NavListItem
                  key={item.id}
                  hideOnSmallNav={item.hideOnMobileNavigation}
                >
                  <NavListLink to={`/${item.slug}/`}>{item.title}</NavListLink>
                  {services && services.length > 0 && (
                    <NavSubList>
                      {services.map(service => {
                        return (
                          <NavListItem key={service.id}>
                            <NavListLink to={`/${item.slug}/${service.slug}/`}>
                              {service.title}
                            </NavListLink>
                          </NavListItem>
                        );
                      })}
                    </NavSubList>
                  )}
                </NavListItem>
              );
            })}
          </NavList>
        )}
      </Container>
    </HeaderWrapper>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  font-size: 0.8em;

  @media (min-width: 50em) {
    padding-bottom: 0;
  }
`;

const Container = styled(CoreContainer)`
  width: 100%;

  @media (min-width: 50em) {
    padding: 0;
  }
`;

const LogoLink = styled(Link)`
  &:focus {
    ${focusState(({ theme }) => theme.palette.white)};
  }
`;

const Logo = styled(LogoSvg)`
  display: block;
  width: 100px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 50em) {
    width: 180px;
    max-width: 100%;
    margin-left: 0;
  }
`;

const VisuallyHidden = styled.span`
  ${hideVisually};
`;

const NavList = styled.ul`
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;

  @media (min-width: 50em) {
    display: block;
    margin-top: 1.5rem;
  }
`;

const NavListItem = styled.li`
  display: ${({ hideOnSmallNav }) => (hideOnSmallNav ? "none" : "flex")};
  margin-top: 0.5rem;

  @media (min-width: 50em) {
    display: block;
  }
`;

const NavListLink = styled(Link)`
  ${fancyLinkStyles(
    ({ theme }) => transparentize(0.6, theme.palette.lightBlue),
    ({ theme }) => transparentize(0.2, theme.palette.white),
    "0.2em"
  )};
  display: inline;
  position: relative;
  text-decoration: none;

  &:focus {
    ${focusState(({ theme }) => theme.palette.white)};
  }
`;

const NavSubList = styled.ul`
  display: none;
  margin: 0.5rem 0 0 1rem;
  padding: 0;
  list-style: none;

  @media (min-width: 50em) {
    display: block;
  }
`;

export default Header;
